<template>
    <div class="text-center my-5 min-vh-100">
        <span class="h1">Страница не найдена!</span>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>
